@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

* {
  font-family: Rubik, sans-serif;
}

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #373a3c;
$gray-900: #212529;
$black: #000;

$blue: #2780E3;
$indigo: #6610f2;
$purple: #613d7c;
$pink: #e83e8c;
$red: #FF0039;
$orange: #F2661F;
$yellow: #BED32B;
$green: #3FB618;
$teal: #20c997;
$cyan: #9954BB;
$lightblue: lighten($blue, 20%);

$primary: $blue;
$secondary: $orange;
$success: $green;
$info: $lightblue;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

@import "~bootswatch/dist/cosmo/_variables.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootswatch/dist/cosmo/_bootswatch.scss";
$fa-font-path: '../fonts';
@import '~font-awesome/scss/font-awesome';
@import '~formiojs/dist/formio.full.css';

.logo {
  height: 1.9em;
}

.text-blue {
  color: #127ABF;
}

.text-green {
  color: #449414;
}

.hero-image {
  display: inline;
  height: 2em;
}

#main {
  margin-top: 10px;
}
