.california-table {
    width: 100%;
    
    thead, .subheading, .col-head {
        text-align: center;
    }

    thead {
        color: #ffffff;
        background-color: #7090ba;
    }

    .subheading {
        font-weight: bold;
        color: #000000;
    }

    .col-head {
        color: #000000;
        background-color: #b6c7dc;
    }

    th, td {
        padding: 4px;
        line-height: 1em;
    }

    #moreThanFive {
        margin-left: 8px;
        margin-right: 8px;
    }

    label {
        margin: 0;
    }
}
